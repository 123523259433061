import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";
var _excluded = ["container", "environment"];
import { createHorizontalSliderComponent, generateHorizontalSliderId, updateNavigationButtonsProps as _updateNavigationButtonsProps } from '@algolia/ui-components-horizontal-slider-vdom';
import { createElement, Fragment, h, render } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { getHTMLElement } from './getHTMLElement';
var UncontrolledHorizontalSlider = createHorizontalSliderComponent({
  createElement: createElement,
  Fragment: Fragment
});

function HorizontalSlider(props) {
  var listRef = useRef(null);
  var nextButtonRef = useRef(null);
  var previousButtonRef = useRef(null);
  var sliderIdRef = useRef(generateHorizontalSliderId());
  useEffect(function () {
    _updateNavigationButtonsProps({
      listRef: listRef,
      nextButtonRef: nextButtonRef,
      previousButtonRef: previousButtonRef
    });
  });
  return h(UncontrolledHorizontalSlider, _extends({}, props, {
    listRef: listRef,
    nextButtonRef: nextButtonRef,
    previousButtonRef: previousButtonRef,
    sliderIdRef: sliderIdRef,
    updateNavigationButtonsProps: function updateNavigationButtonsProps() {
      return _updateNavigationButtonsProps({
        listRef: listRef,
        nextButtonRef: nextButtonRef,
        previousButtonRef: previousButtonRef
      });
    }
  }));
}

export function horizontalSlider(_ref) {
  var container = _ref.container,
      _ref$environment = _ref.environment,
      environment = _ref$environment === void 0 ? window : _ref$environment,
      props = _objectWithoutProperties(_ref, _excluded);

  var children = h(HorizontalSlider, props);

  if (!container) {
    return children;
  }

  render(children, getHTMLElement(container, environment));
  return undefined;
}